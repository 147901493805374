.footer {
    padding: 60px 0px;
}

.footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -60px; /* Move the logo up */
}

.footer__logo img {
    width: 200px; /* Keep the logo size */
    height: auto;
    object-fit: contain;
    object-position: center;
}


.container hr {
    border: 1px solid var(--primary-color);
    margin-bottom: 60px;
}

.footer__wrapper {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;
}

/* Footer Logo */
/* .footer__logo {
    width: 40%;
} */

/* .footer__logo h2 {
    color: var(--primary-color);
} */


.small_text {
    font-size: 1rem;
    margin-top: 30px;
}

/* Solutions Section */
.footer__quick-links {
    width: 20%;
}

.quick__links-title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 30px;
}

.quick__links {
    list-style: none;
}

.quick__link-item {
    margin-bottom: 30px;
}

.quick__link-item a {
    font-size: 0.9rem;
    font-weight: 300;
    cursor: pointer;
}

.quick__link-item a:hover {
    color: var(--primary-color);
}

/* Location Section */
.footer__location {
    color: rgba(255, 255, 255, 0.7);
    width: 20%;
}

.location__title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 20px;
}

.footer__location p {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.light-theme .footer__location p {
    color: #555;
}

/* Social Media Section */
.footer__social-media {
    width: 20%;
}

.social__media-title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 20px;
}

.social__media-icons {
    display: flex;
    gap: 15px;
}

.social__media-icons a {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.3s ease;
}

.social__media-icons a:hover {
    color: var(--primary-color);
}

.light-theme .social__media-icons a
{
    color: #555;
}

.light-theme .social__media-icons a:hover
{
    color: var(--primary-color);
}

/* Copyright */
.copyright {
    color: rgba(255, 255, 255, 0.573);
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
}

/* Divider (hr)
.footer-divider {
    border: 0;
    border-top: 1px solid rgba(84, 83, 83, 0.2);
    margin: 5px 0;
} */

.light-theme .quick__link-item a
{
    font-weight: 500;

}

.light-theme .copyright
{
    color: #555;
}

@media screen and (max-width: 768px) {
    .footer__logo {
        width: 50%;
    }

    .footer__wrapper {
        flex-wrap: wrap;
        column-gap: 1.5rem !important; 
    }
    
    .footer__quick-links {
        margin-bottom: 30px;
    }

    .footer__quick h2
    {
        font-size: 1.5rem;
    }

    .footer__location {
        width: 50%;
    }

    .footer__social-media {
        width: 50%;
    }

    .copyright {
        margin-top: 30px;
    }
}

@media screen and (max-width: 576px)
{
    .footer__quick-links {
        width: 47%;
    }
    
    .footer__logo
    {
        width: 47%;
    }

    .footer__location {
        width: 100%;
    }

    .footer__social-media {
        width: 100%;
    }

    .copyright {
        margin-top: 30px;
    }
}